import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { format, isValid } from 'date-fns'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  MdxWrapper,
  PinBlockGroup,
  AggregatorPin4,
  CategoryTemplate,
  Typography,
} from '../../components'
import { path, pathOr } from 'ramda'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { flattenNodes } from '../../utils'

const propTypes = {
  data: PropTypes.shape({
    portfolio: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        title: PropTypes.string,
        summary: PropTypes.string,
        content: PropTypes.string,
        relatedPublications: PropTypes.array,
        relatedEvents: PropTypes.array,
        relatedCourses: PropTypes.array,
      }),
    }),

    projects: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              excerpt: PropTypes.string,
            }),
          }),
        }),
      ),
    }),

    publications: PropTypes.shape({
      edges: PropTypes.array,
    }),
    events: PropTypes.shape({
      edges: PropTypes.array,
    }),
    courses: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const PortfolioTemplate = ({ data }) => {
  const {
    portfolio: {
      frontmatter: {
        title,
        summary,
        content,
        relatedPublications,
        relatedEvents,
        relatedCourses,
      },
    },
    projects: { edges: projects },
  } = data

  const breadcrumbs = [
    {
      label: 'Catalysing',
      url: '/catalysing',
    },
    {
      label: title,
    },
  ]

  const portfolioProjects = projects.filter(({ node }) =>
    pathOr([], ['frontmatter', 'portfolios'], node).includes(title),
  )

  const publications = flattenNodes(data.publications.edges)
  const events = flattenNodes(data.events.edges)
  const courses = flattenNodes(data.courses.edges)

  const portfolioPublications = !relatedPublications
    ? []
    : publications.filter(publications =>
        relatedPublications.includes(publications.title),
      )
  const portfolioEvents = !relatedEvents
    ? []
    : events.filter(events => relatedEvents.includes(events.title))
  const portfolioCourses = !relatedCourses
    ? []
    : courses.filter(courses => relatedCourses.includes(courses.title))

  return (
    <MDXProvider>
      <CategoryTemplate breadcrumbs={breadcrumbs}>
        <Divider thickness={4} />
        <Typography tag="h1" type="boxHeading" className="mb-8">
          {title}
        </Typography>
        <MdxWrapper>
          {summary && <div>{summary}</div>}
          {content && <MDXRenderer>{content}</MDXRenderer>}
        </MdxWrapper>
        {portfolioProjects.length > 0 && (
          <>
            <Divider thickness={4} />
            <Typography tag="h2" className="mb-8">
              Projects
            </Typography>
            <PinBlockGroup columns={2}>
              {portfolioProjects.map(({ node }, i) => {
                return (
                  <AggregatorPin4
                    key={i}
                    title={path(['frontmatter', 'title'], node)}
                    thumbnailSrc={path(['frontmatter', 'featuredImage'], node)}
                    href={path(['fields', 'slug'], node)}
                  >
                    {path(['frontmatter', 'excerpt'], node)}...
                  </AggregatorPin4>
                )
              })}
            </PinBlockGroup>
          </>
        )}

        {portfolioPublications.length > 0 && (
          <>
            <Divider thickness={4} />
            <Typography tag="h2" className="mb-8">
              Publications
            </Typography>
            <PinBlockGroup columns={2}>
              {portfolioPublications.map((pub, i) => {
                return (
                  <AggregatorPin4
                    key={i}
                    title={pub.title}
                    thumbnailSrc={pub.featuredImage}
                    href={pub.slug}
                  >
                    {pub.excerpt}
                  </AggregatorPin4>
                )
              })}
            </PinBlockGroup>
          </>
        )}

        {portfolioEvents.length > 0 && (
          <>
            <Divider thickness={4} />
            <Typography tag="h2" className="mb-8">
              Events
            </Typography>
            <PinBlockGroup columns={2}>
              {portfolioEvents.map((event, i) => {
                return (
                  <AggregatorPin4
                    key={i}
                    title={event.title}
                    thumbnailSrc={event.featuredImage}
                    href={event.slug}
                  >
                    {event.excerpt}
                  </AggregatorPin4>
                )
              })}
            </PinBlockGroup>
          </>
        )}

        {portfolioCourses.length > 0 && (
          <>
            <Divider thickness={4} />
            <Typography tag="h2" className="mb-8">
              Courses
            </Typography>
            <PinBlockGroup columns={2}>
              {portfolioCourses.map((course, i) => {
                return (
                  <AggregatorPin4
                    key={i}
                    title={course.title}
                    thumbnailSrc={course.featuredImage}
                    href={course.slug}
                  >
                    {isValid(new Date(course.applicationDeadline)) && (
                      <>
                        <strong className="text-teal-light uppercase">
                          Application Deadline
                        </strong>
                        <br />
                        {format(
                          new Date(course.applicationDeadline),
                          'dd MMMM yyyy',
                        )}
                        <br />
                      </>
                    )}
                    {isValid(new Date(course.startDate)) && (
                      <>
                        <strong className="text-teal-light uppercase">
                          Course Starts
                        </strong>
                        <br />
                        {format(new Date(course.startDate), 'dd MMMM yyyy')}
                      </>
                    )}
                  </AggregatorPin4>
                )
              })}
            </PinBlockGroup>
          </>
        )}
      </CategoryTemplate>
    </MDXProvider>
  )
}

PortfolioTemplate.propTypes = propTypes

const ProjectCategory = ({ data }) => {
  const {
    portfolio: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <PortfolioTemplate data={data} />
    </LayoutFullWidth>
  )
}

ProjectCategory.propTypes = propTypes

export default ProjectCategory

/** @todo only fetch projects by category */
export const pageQuery = graphql`
  query PortfolioTemplate($slug: String!) {
    portfolio: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
        title
        summary
        content
        relatedPublications
        relatedEvents
        relatedCourses
      }
    }
    projects: allMdx(
      filter: { fields: { contentType: { eq: "projects" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
            portfolios
          }
        }
      }
    }
    publications: allMdx(
      filter: { fields: { contentType: { eq: "publications" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
    events: allMdx(
      filter: {
        fields: { contentType: { eq: "events" } }
        frontmatter: { archive: { ne: true } }
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            archive
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  aspectRatio
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
    courses: allMdx(
      filter: { fields: { contentType: { eq: "courses" } } }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            applicationDeadline
            startDate
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  aspectRatio
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
